import React from 'react'

const Solutions = () => {
  return (
    <div className="solution">
      <h1 className="sm:text-center xl:text-end">Solutions</h1>
    </div>
  )
}

export default Solutions
